<template>
  <svg v-if="name === 'logo'" viewBox="0 0 1427.15 365.43" fill="#00214B" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd"
      d="m173.31,191.58c-18.78-19.39-44.73-30.16-71.73-29.79-25.64,0-45.1,5.88-58.38,17.63v-68.59c.06-5.71-2.23-11.19-6.34-15.16-3.96-3.99-9.36-6.22-14.98-6.16h-.56c-5.62-.05-11.02,2.17-14.98,6.16C2.23,99.65-.06,105.13,0,110.84v153.03c-.3,26.92,10.54,52.77,29.96,71.43,18.77,19.31,44.69,30.02,71.62,29.6,27,.38,52.96-10.39,71.74-29.78,19.39-18.8,30.17-44.78,29.77-71.79.39-27-10.39-52.96-29.79-71.74m-30.5,113.53c-10.79,11.15-25.71,17.34-41.23,17.09-32.1.15-58.25-25.75-58.4-57.85,0-.16,0-.33,0-.49-.22-32.1,25.63-58.3,57.73-58.52.22,0,.44,0,.66,0,32.23.02,58.34,26.16,58.32,58.39,0,.04,0,.09,0,.13.24,15.52-5.95,30.44-17.09,41.25">
    </path>
    <path fill-rule="evenodd"
      d="m403.52,183.3v96.43c-.17,46.95-38.19,84.96-85.14,85.14-22.69.33-44.5-8.73-60.28-25.03-16.29-15.71-25.36-37.46-25.05-60.1v-96.44c-.06-5.72,2.25-11.2,6.38-15.16,3.9-4.09,9.33-6.39,14.98-6.34h.49c11.78.16,21.27,9.72,21.35,21.5v96.43c.15,23.33,19.18,42.12,42.51,41.97,11.03-.07,21.59-4.45,29.43-12.2,7.95-7.85,12.34-18.61,12.15-29.78v-96.43c.09-11.84,9.67-21.42,21.51-21.5h.37c5.68-.15,11.14,2.17,14.98,6.34,4.12,3.96,6.42,9.44,6.34,15.16">
    </path>
    <path fill-rule="evenodd"
      d="m955.15,110.84v232.73c.13,5.67-2.18,11.13-6.34,14.98-3.9,4.09-9.33,6.38-14.98,6.34h-.54c-5.65.05-11.08-2.24-14.98-6.34-4.16-3.85-6.47-9.31-6.34-14.98V110.84c.02-11.77,9.55-21.3,21.32-21.32h.54c11.77.02,21.3,9.55,21.32,21.32">
    </path>
    <path fill-rule="evenodd"
      d="m955.15,110.84v232.73c.13,5.67-2.18,11.13-6.34,14.98-3.9,4.09-9.33,6.38-14.98,6.34h-.54c-5.65.05-11.08-2.24-14.98-6.34-4.16-3.85-6.47-9.31-6.34-14.98V110.84c.02-11.77,9.55-21.3,21.32-21.32h.54c11.77.02,21.3,9.55,21.32,21.32">
    </path>
    <path fill-rule="evenodd"
      d="m1186.8,277.63c-3.85-5.86-10.44-9.33-17.45-9.18-9.36-.61-17.88,5.41-20.43,14.44-8.19,23.48-30.3,39.24-55.17,39.31-18.93,0-32.86-5.64-41.78-16.91,5.76-2.7,25.26-11.58,58.52-26.63,26.81-12.1,48.45-21.84,64.91-29.23,5.28-2.24,9.36-6.6,11.24-12.01,2.13-5.3,1.88-11.27-.68-16.37-16.58-36.02-52.56-59.14-92.21-59.24-27-.4-52.96,10.38-71.74,29.78-19.43,18.77-30.23,44.74-29.83,71.74-.4,27.02,10.4,53,29.83,71.79,39.69,39.6,103.96,39.53,143.56-.16,10.69-10.71,18.84-23.67,23.88-37.94.93-2.41,1.42-4.97,1.46-7.56-.06-4.28-1.5-8.43-4.09-11.83m-151.4-13.76c-.26-32.06,25.52-58.26,57.57-58.52.25,0,.51,0,.76,0,18.08,0,31.47,5.17,40.17,15.52l-98.52,44.26v-1.26Z">
    </path>
    <path fill-rule="evenodd"
      d="m1362.26,301.02c0,35.53-34.09,63.38-77.68,63.38-25.05,0-48.67-9.81-63.11-26.22-7.87-8.48-7.37-21.74,1.11-29.61,8.48-7.87,21.74-7.37,29.61,1.11.24.26.47.52.7.79,6.53,7.46,18.67,12.06,31.67,12.06,20.46,0,35.75-11.38,35.75-21.5,0-3.74-5.85-9.13-16.73-11.69-30.13-7.11-51.19-13.58-52.05-13.85-24.06-7.46-38.99-26.77-38.99-50.34,0-35.57,34.09-63.42,77.63-63.42,17.86-.19,35.37,5,50.25,14.89,9.53,6.56,11.93,19.6,5.37,29.12-6.56,9.53-19.6,11.93-29.12,5.37-7.89-5.07-17.12-7.67-26.49-7.46-20.46,0-35.75,11.33-35.75,21.5,0,1.8,0,7.38,9.5,10.3.18.05,20.6,6.34,49.3,13.09,29.78,7.06,49.03,27.66,49.03,52.49">
    </path>
    <path fill="#0097ff" fill-rule="evenodd"
      d="m845.33,190.79c-18.83-19.47-44.87-30.3-71.96-29.91-25.72,0-45.23,5.89-58.53,17.68v-40.19c.03-15.23-2.49-30.37-7.43-44.78-24.9-72.4-103.79-110.91-176.19-86.01-40.37,13.88-72.09,45.59-85.99,85.95-4.98,14.41-7.51,29.56-7.49,44.81v124.93c.29,56.26,46.14,101.62,102.39,101.33,56.26-.29,101.62-46.14,101.33-102.39-.29-56.05-45.81-101.33-101.86-101.33-25.68,0-45.19,5.89-58.53,17.68v-40.19c-.43-52.59,41.86-95.57,94.45-96,52.59-.43,95.57,41.86,96,94.45v126.46c-.15,56.26,45.33,102,101.59,102.15,56.26.15,102-45.33,102.15-101.59,0-.36,0-.73,0-1.09.39-27.09-10.44-53.13-29.92-71.95m-347.32,30.93c22.9-23.28,60.33-23.6,83.62-.7,23.28,22.9,23.6,60.33.7,83.62-22.9,23.28-60.33,23.6-83.62.7-11.28-11.09-17.65-26.25-17.67-42.07-.27-15.59,5.87-30.61,16.98-41.55h0Zm316.73,82.92c-22.87,22.84-59.93,22.81-82.77-.06-22.84-22.87-22.81-59.93.06-82.77,22.87-22.84,59.93-22.81,82.77.06,10.95,10.97,17.11,25.84,17.11,41.34.01,15.54-6.17,30.45-17.17,41.43">
    </path>
    <text class="text-[#00214B] text-5xl font-semibold" transform="translate(1362.27 180.89)">TM</text>
  </svg>
</template>

<script>
export default {
  props: ["name"]
}
</script>




